import React from 'react';
import { stockCategoryDictionary } from 'common/stockCategoryDictionary';
import { stockTypeDictionary } from 'common/stockTypeDictionary';
import { ReservationChip } from './ReservationChip';
import { RadioGroup } from 'components/radioGroup/RadioGroup.component';
import { Button } from 'components/button/Button.component';
import { WarningIcon } from 'components/warningIcon/WarningIcon.component';
import uuid from 'react-uuid';
import { useNewOfferFromStockApi } from 'pages/newOfferFromStock/useNewOfferFromStockApi';
export function StockCarsListBodyRow(_a) {
    var stockCar = _a.stockCar, selectedCar = _a.selectedCar, handleOnRowClick = _a.handleOnRowClick, _b = _a.searchingMode, searchingMode = _b === void 0 ? false : _b, handleSetAppError = _a.handleSetAppError;
    var newOfferFromStockApi = useNewOfferFromStockApi();
    var isSelected = selectedCar === stockCar.commonOrderNumber;
    function handleOnSelectStockCar() {
        var offerId = uuid();
        var fyon = stockCar.fyon;
        var _a = window.location.href.split('?'), searchParamsString = _a[1];
        var searchParams = new URLSearchParams(searchParamsString);
        var clientId = searchParams.get('clientId');
        var partyId = searchParams.has('partyId') ? searchParams.get('partyId') : null;
        var context = searchParams.get('context');
        var modelYear = stockCar.modelYear;
        var factoryOrderDate = stockCar.factoryOrderDate;
        // TODO: warunek tylko na czas testów - DO USUNIĘCIA przy VOLVODOL-4664
        var commonOrderNumber = stockCar.commonOrderNumber === '10937777' ? undefined : stockCar.commonOrderNumber;
        var modelId = stockCar.modelId;
        var carOnLineDate = stockCar.carOnLineDate;
        var structureWeek = stockCar.structureWeek;
        newOfferFromStockApi.checkCar(offerId, fyon, clientId, partyId, modelYear, factoryOrderDate, commonOrderNumber)
            .then(function () {
            handleSetAppError(undefined);
            // przejście do nowego kreatora
            var newSearchParams = new URLSearchParams();
            newSearchParams.set('offerIdFromStock', offerId);
            newSearchParams.set('context', context);
            newSearchParams.set('clientId', clientId);
            if (partyId) {
                newSearchParams.set('partyId', partyId);
            }
            window.location.hash = '#/creator?' + newSearchParams.toString();
        })
            .catch(function (error) {
            if (error.response.data.errorCode === 'stock-car-not-exists') {
                // przejście do starego kreatora 
                if (stockCar.oldCreatorAllowed) {
                    var oldCreatorSearchParams = new URLSearchParams();
                    oldCreatorSearchParams.set('modelId', modelId);
                    oldCreatorSearchParams.set('commonOrderNumber', commonOrderNumber);
                    oldCreatorSearchParams.set('carOnLineDate', carOnLineDate);
                    oldCreatorSearchParams.set('structureWeek', structureWeek);
                    oldCreatorSearchParams.set('context', context);
                    oldCreatorSearchParams.set('clientId', clientId);
                    if (partyId) {
                        oldCreatorSearchParams.set('partyId', partyId);
                    }
                    window.location.href = '#/offer-wizard?' + oldCreatorSearchParams.toString();
                }
                else {
                    handleSetAppError({
                        redirect: 'errorPopup',
                        errorId: error.response.data.errorId ? error.response.data.errorId : '',
                        errorDesc: 'Brak zapisanej konfiguracji w systemie. Utwórz ofertę samodzielnie.',
                    });
                }
            }
            else {
                handleSetAppError({
                    redirect: 'errorPopup',
                    errorId: error.response.data.errorId ? error.response.data.errorId : '',
                    errorDesc: 'Nie można otworzyć wskazanej konfiguracji.',
                    retryHandler: handleOnSelectStockCar
                });
            }
        });
    }
    return (React.createElement("div", { className: " ".concat(isSelected && !searchingMode ? 'selectedStockCarListBodyWrapper' : 'stockCarsListBodyRowWrapper'), onClick: handleOnRowClick },
        React.createElement("div", { className: 'stockCarBaseData' },
            React.createElement("div", { className: 'stockCarListColumn stockCarListRadioColumn' }, searchingMode ?
                React.createElement(RadioGroup.Placeholder, null)
                : React.createElement(RadioGroup.Item, { value: stockCar.commonOrderNumber, id: stockCar.commonOrderNumber },
                    React.createElement(RadioGroup.Indicator, null))),
            React.createElement("div", { className: 'stockCarListColumn stockCarBaseColumn' },
                stockCar.reservation ? React.createElement("div", null,
                    React.createElement(ReservationChip, null)) : null,
                React.createElement("div", null,
                    React.createElement("p", null, stockCategoryDictionary(stockCar.stockCategory)),
                    React.createElement("p", null, stockCar.model),
                    React.createElement("p", null, stockCar.modelYear))),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", null, stockTypeDictionary(stockCar.type)),
                React.createElement("p", null, stockCar.dealerOrder),
                React.createElement("p", null, stockCar.vin)),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", null, stockCar.deliveryDate)),
            React.createElement("div", { className: 'stockCarListColumn' },
                React.createElement("p", null, stockCar.lastDateForOrderChange)),
            React.createElement("div", { className: 'stockCarConfigurationColumn stockCarListColumn' },
                React.createElement("div", null,
                    React.createElement("ul", { className: 'stockCarConfigurationList' },
                        React.createElement("li", null, stockCar.drive.split(' ')[0]),
                        React.createElement("li", null, stockCar.version),
                        React.createElement("li", null, stockCar.upholstery),
                        React.createElement("li", null, stockCar.interior))))),
        isSelected ?
            React.createElement("div", { className: 'stockCarDetailedData' },
                React.createElement("div", { className: 'stockCarDetails' },
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Dealer"),
                        React.createElement("p", null, stockCar.dealerName)),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Data produkcji"),
                        React.createElement("p", null, stockCar.productionDate)),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Nap\u0119d"),
                        React.createElement("p", null, stockCar.drive)),
                    React.createElement("div", { className: 'stockCarDetailsRow' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Numer rejestracyjny"),
                        React.createElement("p", null, stockCar.registrationNumber))),
                stockCar.comment ?
                    React.createElement("div", { className: 'stockCarDetailsCommentWrapper' },
                        React.createElement("p", { className: 'stockCarDetailsLabel' }, "Uwagi do konfiguracji:"),
                        React.createElement("p", null, stockCar.comment))
                    : null,
                stockCar.warnings.length > 0 ?
                    React.createElement("div", { className: 'stockCarDetailsWarningsWrapper' },
                        React.createElement("div", { className: 'stockCarDetailsWarnings' },
                            React.createElement("div", { className: 'stockCarWarningIcon' },
                                React.createElement(WarningIcon, null)),
                            React.createElement("div", { className: 'stockCarDetailsWarningsList' }, stockCar.warnings.map(function (warning, index) { return (React.createElement("p", { key: index }, warning)); }))))
                    : null,
                React.createElement("div", { className: 'stockCarDetailsButtonWrapper' },
                    React.createElement("div", { className: 'stockCarDetilsButton' },
                        React.createElement(Button, { onClick: handleOnSelectStockCar }, "wybierz"))))
            : null));
}
